<template>
  <div>
    <v-btn
      v-if="!toggle_form"
      @click="toggle_btn_form()"
      class="mt-10"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-form ref="form" class="mb-10">
      <v-card class="mt-10" v-if="toggle_form">
        <v-card-title>
          <span> {{ title }}</span>
          <v-spacer></v-spacer>

          <v-switch
            v-if="form_obj['status']"
            v-model="form_obj['status'].value"
            :label="
              form_obj['status'].value
                ? form_obj['status'].label[0]
                : form_obj['status'].label[1]
            "
            hide-details
          ></v-switch>
          <!--/col (employee status)-->
        </v-card-title>
        <v-divider></v-divider>
        <!--/.card title -->

        <v-card-text>
          <v-row class="mb-3">
            <v-col
              cols="6"
              v-for="(input, i) in Object.keys(form_obj)"
              :key="i"
              sm="3"
            >
              <v-text-field
                @input="$emit('reset_rules')"
                v-if="
                  form_obj[input].type != 'file' &&
                  form_obj[input].type != 'select' &&
                  form_obj[input].type != 'switch' &&
                  form_obj[input].type != 'date'
                "
                v-model="form_obj[input].value"
                :type="form_obj[input].type"
                :rules="form_obj[input].rules"
              >
                <template slot="label">
                  <strong class="red--text" v-if="form_obj[input].is_require"
                    >*</strong
                  >
                  {{ form_obj[input].label }}
                </template>
              </v-text-field>

              <v-select
                @input="$emit('reset_rules')"
                :items="form_obj[input].items"
                v-else-if="form_obj[input].type == 'select'"
                v-model="form_obj[input].value"
                :rules="form_obj[input].rules"
                :item-text="form_obj[input].item_text"
                :item-value="form_obj[input].item_value"
              >
                <template slot="label">
                  <strong class="red--text" v-if="form_obj[input].is_require"
                    >*</strong
                  >
                  {{ form_obj[input].label }}
                </template>
              </v-select>

              <v-file-input
                v-else-if="form_obj[input].type == 'file'"
                v-model="form_obj[input].value"
                :rules="form_obj[input].rules"
              >
                <template slot="label">
                  <strong class="red--text" v-if="form_obj[input].is_require"
                    >*</strong
                  >
                  {{ form_obj[input].label }}
                </template>
              </v-file-input>

              <div v-else-if="form_obj[input].type == 'date'">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form_obj[input].value"
                      prepend-icon="mdi-calendar"
                      :rules="form_obj[input].rules"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template slot="label">
                        <strong
                          class="red--text"
                          v-if="form_obj[input].is_require"
                          >*</strong
                        >
                        {{ form_obj[input].label }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      الغاء
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      حفظ
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="12" v-if="form_obj_2">
              <h2>{{ title_2 }}</h2>
            </v-col>

            <v-col cols="12" class="row" v-if="form_obj_2">
              <v-col
                cols="6"
                sm="3"
                v-for="(input2, x) in Object.keys(form_obj_2)"
                :key="x"
              >
                <v-text-field
                  v-if="
                    form_obj_2[input2].type != 'file' &&
                    form_obj_2[input2].type != 'select' &&
                    form_obj_2[input2].type != 'switch' &&
                    form_obj_2[input2].type != 'date'
                  "
                  v-model="form_obj_2[input2].value"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ form_obj_2[input2].label }}
                  </template>
                </v-text-field>

                <v-select
                  :items="form_obj_2[input2].items"
                  v-else-if="form_obj_2[input2].type == 'select'"
                  v-model="form_obj_2[input2].value"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ form_obj_2[input2].label }}
                  </template>
                </v-select>

                <v-file-input
                  v-else-if="form_obj_2[input2].type == 'file'"
                  v-model="form_obj_2[input2].value"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ form_obj_2[input2].label }}
                  </template>
                </v-file-input>

                <div v-else-if="form_obj_2[input2].type == 'date'">
                  <v-menu
                    ref="menu"
                    v-model="form_obj_2[input2].prop"
                    :close-on-content-click="false"
                    :return-value.sync="form_obj_2[input2].value"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form_obj_2[input2].value"
                        label="Picker in menu"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        الغاء
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        حفظ
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-col>
          </v-row>
          <!--/ row -->

          <div class="text-end">
            <v-btn
              @click="
                $refs.form.reset(),
                  $refs.form.resetValidation(),
                  $emit('reset_id')
              "
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1">delete</v-icon>
              مسح
            </v-btn>
            <v-btn
              :disabled="save_loading"
              :loading="save_loading"
              @click="validate()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              حفظ
            </v-btn>

            <v-btn
              @click="toggle_btn_form()"
              class="me-2"
              color="lightGray"
              outlined
            >
              رجوع
              <v-icon small class="me-1">arrow_back</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <!--/ form -->
    </v-form>

    <v-snackbar
      @input="
        ($event) => $emit('close_snack', { props: $event, type: 'success' })
      "
      type="success"
      left
      :value="form_success"
    >
      <p>
        {{ success_text }}
      </p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <!--/ alert success -->

    <v-snackbar
      @input="
        ($event) => $emit('close_snack', { props: $event, type: 'error' })
      "
      color="error"
      left
      :value="form_error"
    >
      {{ error_text }}
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>

    <!--/ alert errors -->
  </div>
</template>

<script>
export default {
  name: "BaseForm",
  props: [
    "form_obj",
    "title",
    "form_obj_2",
    "title_2",
    "form_error",
    "form_success",
    "error_text",
    "success_text",
    "save_loading",
  ],
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toggle_form: false,
    };
  },
  watch: {
    employee_index(newVal) {
      console.log(this.employee_data);
    },
  },
  computed: {
    has_status() {
      return Object.keys();
    },
  },
  methods: {
    toggle_btn_form() {
      this.toggle_form = !this.toggle_form; // toggel form propery
      if (!this.toggle_form) {
        // if form is close back the employee index to default
        this.$emit("reset_id");
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    fade_form() {
      this.toggle_form = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("save_form");
      }
    },
  },
  created() {},
};
</script>
