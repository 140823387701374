<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <BaseTable
      ref="table"
      :headers="headers"
      :items="types"
      :status="true"
      :delete_meg="'حذف الشرط ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_type($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
    ></BaseTable>
    <!--table -->

    <BaseForm
      v-if="data_loaded"
      :title="title"
      :form_obj="form_obj"
      ref="emForm"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(condition_id = null), (title = 'اضافة شرط')"
      @reset_rules="reset_rules()"
      @close_snack="close_snack($event)"
    ></BaseForm>
  </v-container>
  <!--/ container -->
</template>

<script>
import BaseTable from "@/components/base/table";
import BaseForm from "@/components/base/form";

export default {
  name: "expenses",

  data() {
    return {
      rules_props: {
        name_exist_prop: false,
      },
      delete_dialog: false,
      condition_id: null,
      delete_loader: false,
      types: [],
      title: "اضافة شرط",
      data_loaded: false,
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },
      form_obj: {
        condition_name: {
          value: "",
          label: "اسم الشرط",
          type: "text",
          is_require: true,
          rules: [
            (v) => !this.name_exist || "موجود مسبقا",
            (v) => !!v || "حقل مطلوب",
          ],
        },
        status: {
          value: true,
          label: ["فعال", "غير فعال"],
          type: "switch",
        },
      },
      headers: [
        {
          value: "condition_name",
          text: "اسم الشرط",
          align: "center",
        },
        {
          value: "status",
          text: "الحالة",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },
  computed: {
    name_exist() {
      return this.rules_props.name_exist_prop;
    },
  },
  methods: {
    get_conditions() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "conditions",
            method: "get",
          },
        })
        .then((res) => {
          this.types = Object.assign([], res.data.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save_form() {
      this.save_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.condition_id
              ? `conditions/${this.condition_id}`
              : `conditions`,
            method: this.condition_id ? "put" : "post",
          },
          data: {
            condition_name: this.form_obj.condition_name.value,
            status: this.form_obj.status.value ? 1 : 0,
          },
        })
        .then((res) => {
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة الشرط بنجاح";
          this.alerts.success = true;
          this.$refs.emForm.toggle_btn_form();
          this.get_conditions();
        })
        .catch((err) => {
          this.save_loading = false;
          if (err.response.data.data.condition_name) {
            this.rules_props.name_exist_prop = true;
          }
          this.alerts.error_text = "برجاء مراجعة البيانات";
          this.alerts.error = true;
          this.$refs.emForm.$refs.form.validate();
          console.log(err.response);
        });
    },
    reset_rules() {
      Object.keys(this.rules_props).forEach((e) => {
        this.rules_props[e] = false;
      });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    edit_row(ev) {
      this.title = "تعديل الشرط";
      Object.keys(ev.item).forEach((e) => {
        if (this.form_obj[e]) {
          this.form_obj[e].value = ev.item[e];
        }
      });
      console.log(this.form_obj);
      this.condition_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    delete_type(condition_id) {
      this.delete_dialog = false;
      if (condition_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `conditions/${condition_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف الشرط";
            this.delete_loader = false;
            this.get_conditions();
            this.delete_dialog = false;
            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          });
      }
    },
  },
  created() {
    this.get_conditions();
  },
  components: {
    BaseTable,
    BaseForm,
  },
};
</script>
